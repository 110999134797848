import { Tabs, Typography } from 'antd';
import React, { createContext, useLayoutEffect, useState } from 'react';
import Banner from '../../components/DealNgon/Banner';
import Category from '../../components/DealNgon/Category';
import FlashSale from '../../components/DealNgon/FlashSale';
import Icons from '../../components/DealNgon/Icons';
import ListCategory from '../../components/DealNgon/ListCategory';
import PromotionUs from '../../components/DealNgon/PromotionUs';
import Welcome from '../../components/DealNgon/Welcome';
import Tags from '../../components/DealNgon/Tags';
import Website from '../../components/DealNgon/Website';
import actions from '../../redux/actions';
import dispatch from '../../utils/dispatch';

const { Title } = Typography;

const TABS = [
  { key: 'banner', tab: 'Banner', Component: Banner },
  { key: 'list', tab: 'ListCategory', Component: ListCategory },
  { key: 'flash-sale', tab: 'Flash Sale', Component: FlashSale },
  { key: 'website', tab: 'Website', Component: Website },
  { key: 'category', tab: 'Category', Component: Category },
  { key: 'tags', tab: 'Tags', Component: Tags },
  { key: 'icons', tab: 'Icons', Component: Icons },
  { key: 'promotion-us', tab: 'Khuyến mãi Mỹ', Component: PromotionUs },
  { key: 'welcome', tab: 'Welcome', Component: Welcome }
];

export const DealNgonContext = createContext();

export default function DealNgon() {
  const [tab, setTab] = useState(TABS[0].key);

  useLayoutEffect(() => {
    dispatch(actions.getConfiguration());
    dispatch(actions.getListDNCategory());
    dispatch(actions.getListTags());
    dispatch(actions.getListWebsites());
    dispatch(actions.getListIcons());
    dispatch(actions.getPromotionUs());
    return () => dispatch(actions.clearConfiguration());
  }, []);

  return (
    <DealNgonContext.Provider value={{ tab }}>
      <div className="container dealngon">
        <Title className="title" level={5}>
          Hoạt động
        </Title>
        <Tabs onChange={setTab}>
          <>
            {TABS.map(tabItem => {
              return (
                <Tabs.TabPane
                  active={tabItem.key === tab}
                  tab={tabItem.tab}
                  key={tabItem.key}
                >
                  <tabItem.Component />
                </Tabs.TabPane>
              );
            })}
          </>
        </Tabs>
      </div>
    </DealNgonContext.Provider>
  );
}
